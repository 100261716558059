import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Paragraph02, Subheading} from '../core/Typography/Typography.styles';
import {FaqContent, FaqTitle, FaqWrapper} from './LocalStyles';
import {Hidden} from 'react-awesome-styled-grid';

const FaqItem = ({faq}) => {
  const {title, content} = faq;
  const [isFaqOpen, setIsFaqOpen] = useState(false);

  const toggleOpen = () => {
    setIsFaqOpen(!isFaqOpen);
  };

  return (
    <FaqWrapper>
      <FaqTitle onClick={toggleOpen} isOpen={isFaqOpen}>
        <Hidden lg xl>
          <Paragraph02 small fontWeight="medium">
            {title}
          </Paragraph02>
        </Hidden>
        <Hidden xs sm md>
          <Subheading small fontWeight="medium">
            {title}
          </Subheading>
        </Hidden>
      </FaqTitle>
      <FaqContent isOpen={isFaqOpen}>{content}</FaqContent>
    </FaqWrapper>
  );
};

export default FaqItem;

FaqItem.propTypes = {
  faq: PropTypes.object,
};
