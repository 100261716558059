import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import Layout from '../Layout/index.js';
import SearchCard from '../BlogLandingPage/SearchCard';
import {Col, Container, Row} from 'react-awesome-styled-grid';
import {Separator1, Separator2} from '../BlogLandingPage/LocalStyles';
import SubsCard from '../BlogLandingPage/SubsCard';
import FaqCategory from './FaqCategory';
import {ContactSection, FaqSection, MainSection} from './LocalStyles';
import {Heading02, Heading05} from '../core/Typography/Typography.styles';
import FaqItem from './FaqItem';
import SEO from '../seo';
import {faqs} from '../../FAQs';
import {Helmet} from 'react-helmet';

const KnowledgeBasePage = ({data, pageContext}) => {
  const knowledgebase = useMemo(() => {
    let questions;
    if (data.knowledgebase.edges.length > 0) {
      questions = data.knowledgebase.edges.map(item => item.node);
    }
    const faqObject = {};
    const faqMapping = {
      0: {
        name: 'Live Streaming Platform',
        kb_ids: [100841, 101090, 100904, 101106, 101402],
      },
      1: {
        name: 'Video on Demand (VOD)',
        kb_ids: [101180, 100883, 101843, 101774, 101053],
      },
      2: {
        name: 'Content Management',
        kb_ids: [100878, 101084, 101108, 101266, 100918],
      },
      3: {
        name: 'Monetization and Paywall',
        kb_ids: [101231, 100989, 100872, 101672, 100975],
      },
      4: {
        name: 'Video Analytics',
        kb_ids: [101116, 101010, 101151, 102172, 101112],
      },
      5: {
        name: 'HTML5 Video Player',
        kb_ids: [101131, 100937, 101077, 101556, 101818],
      },
    };
    Object.values(faqMapping).map(
      mapped =>
        (faqObject[mapped.name] = questions.filter(
          question => mapped.kb_ids.indexOf(question.databaseId) != -1
        ))
    );
    return faqObject;
  }, [data]);

  const searchCategories = useMemo(() => {
    if (data.knowledgebaseCategories.edges.length > 0) {
      let allQuestions;
      let returnQuestions;
      let validCategories;
      returnQuestions = [];
      validCategories = [
        'mobile-live-streaming',
        'video-on-demand-vod',
        'video-analytics',
        'manage-account',
        'player',
        'videohosting',
        'dacast-ovp',
        'monetize-video',
        'encoders',
        'livestreaming',
      ];
      allQuestions = data.knowledgebaseCategories.edges.map(item => item.node);
      allQuestions.forEach(function (q) {
        if (validCategories.includes(q.slug)) {
          if (q.slug == 'dacast-ovp') {
            q.name = 'Video Platform';
          }
          if (q.slug == 'video-on-demand-vod') {
            q.name = 'VOD';
          }
          if (q.slug == 'mobile-live-streaming') {
            q.name = 'Mobile';
          }

          returnQuestions.push(q);
        }
      });
      return returnQuestions;
    }
    return [];
  }, [data]);

  const locales = useMemo(() => {
    if (data.locales.edges.length > 0) {
      return data.locales.edges.map(item => item.node);
    }
    return [];
  }, [data]);

  const seo = useMemo(() => {
    return {
      canonical: `https://www.dacast.com/${
        pageContext.language === 'en' ? '' : `${pageContext.language}/`
      }support/knowledgebase/`,
      title: 'Best Knowledge Base for Live Streaming and Video Hosting',
      metaDesc:
        'Looking for the best tips on how to live stream and host your online video ? Check out Dacast video and written tutorials and enjoy 30 days of free streaming!',
      opengraphType: 'article',
      opengraphSiteName: 'Dacast',
      opengraphPublisher: 'https://www.facebook.com/DacastStreaming/',
      opengraphPublishedTime: '',
      opengraphModifiedTime: '2020-06-15T23:08:41+00:00',
      schema: {
        articleType: [],
        pageType: ['WebPage'],
        raw:
          '{"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://www.dacast.com/#website","url":"https://www.dacast.com/","name":"Dacast","description":"Video Hosting &amp; Live Streaming Solutions","potentialAction":[{"@type":"SearchAction","target":{"@type":"EntryPoint","urlTemplate":"https://www.dacast.com/?s={search_term_string}"},"query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://www.dacast.com/support/knowledgebase/#webpage","url":"https://www.dacast.com/support/knowledgebase/","name":"Best Knowledge Base for Live Streaming and Video Hosting","isPartOf":{"@id":"https://www.dacast.com/#website"},"datePublished":"2019-01-10T22:06:28+00:00","dateModified":"2020-06-15T23:08:41+00:00","description":"Looking for the best tips on how to live stream and host your online video ? Check out Dacast video and written tutorials and enjoy 30 days of free streaming!","breadcrumb":{"@id":"https://www.dacast.com/support/knowledgebase/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://www.dacast.com/support/knowledgebase/"]}]},{"@type":"BreadcrumbList","@id":"https://www.dacast.com/support/knowledgebase/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"https://www.dacast.com/"},{"@type":"ListItem","position":2,"name":"24/7 Support","item":"https://www.dacast.com/support/"},{"@type":"ListItem","position":3,"name":"Knowledge Base"}]}]}',
      },
    };
  }, [data]);

  const translated = useMemo(() => {
    if (data.knowledgeBasePageData.edges.length > 0) {
      return data.knowledgeBasePageData.edges.map(item => item.node).translated;
    }
    return [];
  }, [data]);

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={seo.canonical} />
      </Helmet>
      <SEO
        seo={seo}
        lang={pageContext.language}
        locales={locales}
        translated={translated}
      />
      <MainSection>
        <Container>
          <Row>
            <Col>
              <SearchCard
                categories={searchCategories}
                type="faq"
                backdrop="https://www.dacast.com/wp-content/uploads/2021/12/KB.png"
              />
            </Col>
          </Row>
          <Separator1 />
          <Separator2 />
          <Row>
            {Object.entries(knowledgebase).map(([category, questions]) => {
              return (
                <Col xs={4} sm={4} lg={4} key={category}>
                  <FaqCategory category={category} questions={questions} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </MainSection>
      <FaqSection>
        <Heading02 small fontWeight="medium">
          Frequently Asked Questions
        </Heading02>
        <Heading05 small fontWeight="medium">
          Frequently Asked Questions
        </Heading05>
        <Separator1 />
        <Separator2 />
        {faqs.map((faq, index) => (
          <FaqItem faq={faq} key={index} />
        ))}
      </FaqSection>
      <ContactSection>
        <Container>
          <Row>
            <Col xs={4} sm={4} lg={8}>
              <SubsCard
                type="contact"
                backdrop="https://www.dacast.com/wp-content/uploads/2021/12/gradienta-m_7p45JfXQo-unsplash-min-scaled.jpg"
              />
            </Col>
            <Col xs={4} sm={4} lg={4}>
              <SubsCard
                type="chat"
                backdrop="https://www.dacast.com/wp-content/uploads/2021/12/jason-leung-mZNRsYE9Qi4-unsplash-min-scaled.jpg"
              />
            </Col>
          </Row>
        </Container>
      </ContactSection>
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    knowledgeBasePageData: allWpPost(filter: {id: {eq: "31202"}}) {
      edges {
        node {
          id
          content
          seo {
            canonical
            title
            metaDesc
            opengraphType
            opengraphSiteName
            opengraphPublisher
            opengraphPublishedTime
            opengraphModifiedTime
            schema {
              articleType
              pageType
              raw
            }
          }
          translated {
            seo {
              canonical
              title
              metaDesc
              opengraphType
              opengraphSiteName
              opengraphPublisher
              opengraphPublishedTime
              opengraphModifiedTime
              schema {
                articleType
                pageType
                raw
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    faqCategories: allWpFaqCategory {
      edges {
        node {
          name
        }
      }
    }
    faqs: allWpFaq {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          content
          translated {
            id
            link
            title
            seo {
              metaRobotsNofollow
              metaRobotsNoindex
              breadcrumbs {
                text
                url
              }
              canonical
              cornerstone
              focuskw
              metaDesc
              metaKeywords
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphTitle
              opengraphSiteName
              opengraphType
              opengraphUrl
              title
              twitterDescription
              twitterTitle
              schema {
                articleType
                pageType
                raw
              }
            }
            locale {
              locale
              id
            }
          }
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          seo {
            metaRobotsNofollow
            metaRobotsNoindex
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            opengraphAuthor
            opengraphDescription
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphTitle
            opengraphSiteName
            opengraphType
            opengraphUrl
            title
            twitterDescription
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
          author {
            node {
              url
              slug
              name
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          internal {
            type
          }
        }
      }
    }
    knowledgebaseCategories: allWpArticleCategory {
      edges {
        node {
          name
          uri
          slug
        }
      }
    }
    knowledgebase: allWpArticle {
      edges {
        node {
          id
          databaseId
          title
          slug
          guid
          uri
          slug
          link
          articleCategories {
            nodes {
              name
            }
          }
          translated {
            id
            link
            title
            seo {
              metaRobotsNofollow
              metaRobotsNoindex
              breadcrumbs {
                text
                url
              }
              canonical
              cornerstone
              focuskw
              metaDesc
              metaKeywords
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphTitle
              opengraphSiteName
              opengraphType
              opengraphUrl
              title
              twitterDescription
              twitterTitle
              schema {
                articleType
                pageType
                raw
              }
            }
            locale {
              locale
              id
            }
          }
          locale {
            locale
            id
          }
          dateGmt
          modifiedGmt
          seo {
            metaRobotsNofollow
            metaRobotsNoindex
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            opengraphAuthor
            opengraphDescription
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphTitle
            opengraphSiteName
            opengraphType
            opengraphUrl
            title
            twitterDescription
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
          author {
            node {
              url
              slug
              name
              seo {
                social {
                  facebook
                  instagram
                  linkedIn
                  twitter
                }
              }
              avatar {
                url
                foundAvatar
              }
            }
          }
          internal {
            type
          }
        }
      }
    }
  }
`;

export default KnowledgeBasePage;

KnowledgeBasePage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
