import styled, {css} from 'styled-components';
import menuArrow from '../../images/menu-arrow-down.svg';
import arrowBack from '../../images/arrow-back.svg';

export const MainSection = styled.main`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  margin: 0;
  background-color: ${({theme}) => theme.colorGuide.primary[50]};
  @media screen and (min-width: 768px) {
    h5 {
      display: none;
    }
    padding-bottom: 60px;
  }
  @media screen and (min-width: 1440px) {
    width: 100%;
    h2 {
      display: inline-block;
    }
  }
`;
export const SectionContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colorGuide.shades[0]};
  box-shadow: 0px 12px 25px 5px #4967ee26;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  position: relative;
  @media screen and (max-width: 767px) {
    border-radius: 0px;
  }
`;
export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60px;
  background-color: ${({theme}) => theme.colorGuide.primary[800]};
  color: ${({theme}) => theme.colorGuide.neutral[900]} !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  @media screen and (max-width: 767px) {
    border-radius: 0px;
  }
`;
export const SectionItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 40px 0;
  width: 100%;
  text-overflow: ellipsis;
  & > p {
    overflow: hidden;
  }
`;
export const ItemContainer = styled.div`
  display: flex;
  text-align: left;
  & > a > p {
    transition: color 0.2s ease-in-out;
  }
  &:hover {
    & > a > p {
      color: ${({theme}) => theme.colorGuide.primary[600]} !important;
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 16px;
  }
  @media screen and (min-width: 768px) {
    margin: 16px;
  }
  @media screen and (min-width: 1440px) {
    margin: 12px 24px;
  }
`;
export const ShowAllContainer = styled.div`
  display: flex;
  text-align: left;
  position: absolute;
  bottom: 12px;
  left: 24px;
  & > a > p {
    transition: color 0.2s ease-in-out;
  }
  &:hover {
    & > a > p {
      color: ${({theme}) => theme.colorGuide.primary[400]} !important;
    }
  }
`;
export const FaqSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 90px 40px 80px;
  background-color: ${({theme}) => theme.colorGuide.shades[0]};
  margin: 0 auto;
  text-align: center;
  max-width: 927px;
  @media screen and (max-width: 767px) {
    padding: 40px 16px 10px 16px;
  }
  @media screen and (max-width: 767px) {
    h2 {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    h5 {
      display: none;
    }
  }
  @media screen and (min-width: 1440px) {
    width: 100%;
    h2 {
      display: inline-block;
    }
  }
`;
export const FaqWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid ${({theme}) => theme.colorGuide.neutral[200]};
  p {
    text-align: left;
  }
  &:last-of-type {
    border-bottom: none;
  }
`;
export const FaqTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 12px 0;
  text-align: left;
  padding: 10px 0 16px 0;
  cursor: pointer;
  &::after {
    display: inline-block;
    content: ' ';
    background: ${({theme}) => theme && theme.colorGuide.neutral[900]};
    mask: url(${menuArrow}) center no-repeat;
    margin: 0 6px;
    padding: 6px;
  }
`;
export const FaqContent = styled.div`
  opacity: 0;
  text-align: justify;
  text-justify: inter-word;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s ease-in, max-height 0.3s ease-in,
    margin-bottom 0.3s ease-in;
  ul {
    list-style-type: circle;
  }
  color: ${({theme}) => theme.colorGuide.neutral[700]};
  ${({isOpen}) =>
    isOpen &&
    css`
      max-height: 2000px;
      opacity: 1;
      margin-bottom: 10px;
    `}
`;
export const ContactSection = styled.section`
  display: block;
  margin: 0;
  background-color: ${({theme}) => theme.colorGuide.primary[50]};

  @media screen and (max-width: 767px) {
    padding: 20px 0;
  }
  @media screen and (min-width: 768px) {
    padding: 30px 0;
  }
  @media screen and (min-width: 1440px) {
    padding: 80px 0;
  }
`;
export const BackToCategories = styled.div`
  display: inline-block;
  color: ${({theme}) => theme.colorGuide.primary[500]};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({theme}) => theme.colorGuide.primary[400]};
    &::before {
      background: ${({theme}) => theme.colorGuide.primary[400]};
    }
  }
  &::before {
    display: inline-block;
    content: ' ';
    background: ${({theme}) => theme && theme.colorGuide.primary[500]};
    mask: url(${arrowBack}) center no-repeat;
    margin: 0 16px;
    padding: 6px;
  }
`;
export const LinkContainer = styled.div`
  display: block;
  text-align: left;
  cursor: pointer;
  & > p {
    transition: color 0.2s ease-in-out;
    display: inline-block;
  }
  &:hover {
    & > p {
      color: ${({theme}) => theme.colorGuide.primary[400]} !important;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 16px;
  }
  @media screen and (min-width: 768px) {
    margin: 36px 16px;
  }
  @media screen and (min-width: 1440px) {
    margin: 44px 16px;
  }
`;
export const CategoryContainer = styled.div`
  display: block;
  text-align: left;
  cursor: pointer;
  & > p {
    transition: color 0.2s ease-in-out;
    display: inline-block;
  }
  &:hover {
    & > p {
      color: ${({theme}) => theme.colorGuide.primary[600]} !important;
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 16px;
  }
  @media screen and (min-width: 768px) {
    margin: 36px 16px;
  }
  @media screen and (min-width: 1440px) {
    margin: 44px 16px;
  }
`;
