import {Link} from 'gatsby-plugin-react-i18next';
import {Link as GatsbyLink} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {Heading04, Paragraph02} from '../core/Typography/Typography.styles';
import {
  ItemContainer,
  SectionContainer,
  SectionItems,
  SectionTitle,
  ShowAllContainer,
} from './LocalStyles';

const FaqCategory = ({category, questions}) => {
  return (
    <SectionContainer>
      <SectionTitle>
        <Heading04 fontWeight="medium" small palette="shades" scale="0">
          {category}
        </Heading04>
      </SectionTitle>
      <SectionItems>
        {questions.slice(0, 5).map(({title, id, uri}) => (
          <ItemContainer key={id}>
            <GatsbyLink to={uri}>
              <Paragraph02 fontWeight="regular">{title}</Paragraph02>
            </GatsbyLink>
          </ItemContainer>
        ))}
      </SectionItems>
      {questions.length > 5 && (
        <GatsbyLink>
          <ShowAllContainer>
            <Paragraph02 fontWeight="regular" palette="primary" scale="500">
              Show All
            </Paragraph02>
          </ShowAllContainer>
        </GatsbyLink>
      )}
    </SectionContainer>
  );
};

export default FaqCategory;

FaqCategory.propTypes = {
  category: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object),
};
