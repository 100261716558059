import React from 'react';
import {Paragraph02} from '../components/core/Typography/Typography.styles';

export const faqs = [
  {
    title: 'Does Dacast Provide Customer Support for My Viewers?',
    content: (
      <Paragraph02 small fontWeight="regular">
        Dacast does not provide customer support for the viewers, but only to
        its customers. If you are interested in live customer support for your
        viewers, this may be possible at an extra cost via our professional
        services.
      </Paragraph02>
    ),
    additional_resources: [
      {
        title: 'How to Use Dacasts New Paywall',
        uri: '/blog/choose-paywall-pay-per-view-streaming/',
      },
      {
        title: 'How to Choose a Paywall Monetization Method',
        uri:
          '/support/knowledgebase/how-long-to-get-paid-after-selling-through-a-paywall/',
      },
      {
        title:
          'How Long Does it Take to Get Paid After Selling Through a Paywall?',
        uri: '/support/knowledgebase/what-is-a-paywall/',
      },
      {
        title: 'What is a Paywall?',
        uri:
          '/support/knowledgebase/intro-to-live-streaming-a-step-by-step-walkthrough/',
      },
      {
        title: 'Intro to Live Streaming: A Walkthrough',
        uri: '/support/knowledgebase/live-stream-recording-tutorial/',
      },
      {
        title: 'Live Stream Recording Tutorial',
        uri:
          '/support/knowledgebase/is-my-live-stream-auto-recorded-or-saved-on-the-dacast-platform/',
      },
      {
        title:
          'Is My Live Stream Auto-Recorded or Saved on the Video Platform?',
        uri:
          '/support/knowledgebase/is-my-live-stream-auto-recorded-or-saved-on-the-dacast-platform/',
      },
    ],
  },
  {
    title: 'What Payment Methods do You Accept for My Dacast account?',
    content: (
      <Paragraph02 small fontWeight="regular">
        As our main payment sources, Dacast supports both credit cards (all
        major credit cards accepted) and PayPal. If you wish to purchase an
        event plan or an annual plan you can also pay by check or wire transfer.
        For wire transfer information please contact one of our sales
        representatives.
      </Paragraph02>
    ),
    additional_resources: [
      {
        title: 'Does Dacast Offer Discounts to Certain Types of Organizations?',
        uri:
          '/ufaqs/does-dacast-provide-any-discounts-for-certain-types-of-organizations/',
      },
      {
        title: 'Does my Dacast Subscription Automatically Renew?',
        uri: '/ufaqs/dacast-subscription-renewal/',
      },
      {
        title: 'How Do I Update my Billing Information?',
        uri: '/ufaqs/update-billing-information/',
      },
      {
        title: 'Mailinator and Yopmail Setup: Why Wont it Work on Dacast?',
        uri: '/ufaqs/i-cant-signup-with-yopmail-and-mailinator-help/',
      },
      {
        title: 'What does GB stand for and how does it relate to Dacast?',
        uri: '/ufaqs/what-does-gb-stand-for-and-how-does-it-relate-to-dacast/',
      },
      {
        title: 'When does Event Plan Bandwidth expire?',
        uri: '/ufaqs/when-does-event-plan-bandwidth-expire/',
      },
      {title: 'When Will I Be Billed?', uri: '/ufaqs/billing-time-frame/'},
    ],
  },
  {
    title: 'What is the Video Viewer Limit on Regular Accounts with Dacast?',
    content: (
      <Paragraph02 small fontWeight="regular">
        There is no viewer limit for videos on regular Dacast accounts. We have
        facilitated broadcasts with hundreds of thousands of concurrent viewers.
        Dacast uses a global Content Delivery Network (CDN) that allows users to
        stream to vast audiences without the concern of hitting a limit that
        would overwork a smaller server network. This top tier CDN is available
        on all plans. If you plan on streaming to large numbers of viewers,
        please make sure you purchase the appropriate amount of bandwidth. Any
        questions or you want to access this feature? Please contact us.
      </Paragraph02>
    ),
    additional_resources: [
      {
        title: 'Free Trial Account: Are There Any Limitations?',
        uri:
          '/support/knowledgebase/free-trial-account-are-there-any-limitations/',
      },
      {
        title: 'How Do I Get More Than 15 viewers?',
        uri: '/support/knowledgebase/how-do-i-get-more-than-15-viewers/',
      },
      {
        title: 'Can I Limit Free Access to a Stream?',
        uri: '/support/knowledgebase/can-i-limit-free-access-to-a-stream/',
      },
      {
        title: 'How Do I Upgrade my Free Trial Account?',
        uri:
          '/support/knowledgebase/how-do-i-upgrade-from-a-free-trial-to-a-normal-account/',
      },
      {
        title: 'Can I stream ad-free with Dacast?',
        uri: '/support/knowledgebase/can-i-stream-ad-free-with-dacast/',
      },
    ],
  },
  {
    title:
      'What Can I Do if I Experience a Video API Issue in My Dacast Account?',
    content: (
      <>
        <Paragraph02 fontWeight="regular">
          Our documentation is automatically updated each time we improve our
          API. You can test each API call directly in the documentation. To do
          so, please enter your broadcaster ID (BID) and your API key using the
          following format: broadcaster ID_API key Those information are
          available in your API section under your PREFERENCES section
        </Paragraph02>
      </>
    ),
  },
  {
    title: 'I was billed from DACAST INC, what was this?',
    content: (
      <Paragraph02 fontWeight="regular">
        If you haven’t signed up for a Dacast account and are seeing this on
        your credit card statement, chances are you bought access to a
        stream/video recently from one of our live streaming customers or
        partners. So either you did a Pay-Per-View for an event or signed up for
        a subscription. Try to line up when you purchased and if you watched
        anything that day for which required payment.
      </Paragraph02>
    ),
    additional_resources: [
      {
        uri: '/pay-per-view-streaming/',
        title: 'What is the Dacast Pay-Per-View Streaming Platform?',
      },
      {
        uri:
          '/support/knowledgebase/video-monetization-how-to-use-dacasts-new-paywall/',
        title: 'How to Use Dacasts New Paywall',
      },
      {
        uri: '/blog/choose-paywall-pay-per-view-streaming/',
        title: 'How to Choose a Paywall Monetization Method',
      },
      {
        uri:
          '/support/knowledgebase/how-long-to-get-paid-after-selling-through-a-paywall/',
        title:
          'How Long Does it Take to Get Paid After Selling Through a Paywall?',
      },
      {
        uri: '/support/knowledgebase/what-is-a-paywall/',
        title: 'What is a Paywall?',
      },
    ],
  },
  {
    title: 'Is There a Limited Number of Live Channels in My Dacast Account?',
    content: (
      <Paragraph02 fontWeight="regular">
        There are no live channels limits in your Dacast account. However, by
        default, you cannot create more than 20 live channels in your Dacast
        account without contacting us. If you need more than 20 live channels in
        your account, please contact us and we will make it happen.
      </Paragraph02>
    ),
    additional_resources: [
      {
        uri: '/blog/how-to-broadcast-live-video-in-html5/',
        title: 'How to Broadcast Live Video in HTML5',
      },
      {
        uri:
          '/support/knowledgebase/free-trial-account-are-there-any-limitations/',
        title: 'Free Trial Account: Are there Any Limitations?',
      },
      {
        uri: '/support/knowledgebase/how-long-does-the-free-trial-last/',
        title: 'How Long Does the Free Trial Last?',
      },
      {
        uri:
          '/support/knowledgebase/how-do-i-upgrade-from-a-free-trial-to-a-normal-account/',
        title: 'How Do I Upgrade from a Free Trial to a Normal Account?',
      },
    ],
  },
  {
    title: 'What Do I Do When My Video Bandwidth Limit is Exceeded?',
    content: (
      <Paragraph02 small fontWeight="regular">
        Set up Playback Protection, which is essentially overage protection. In
        this scenario, you put a credit card on file that is authorized to
        refill your bank of Bandwidth if you go over your monthly bandwidth or
        Event Plan bandwidth. You can check out our Playback Protection
        Walkthrough for more details. An alternative option is just letting the
        bandwidth run out. This will cause your streams to shut off when you
        burn through the bandwidth on your account, but you will not suffer any
        additional consequences.
      </Paragraph02>
    ),
    additional_resources: [
      {
        uri:
          '/support/knowledgebase/does-streaming-on-facebook-consume-bandwidth/',
        title: 'Does Streaming on Facebook Consume Bandwidth?',
      },
      {
        uri:
          '/support/knowledgebase/how-come-i-cant-create-another-channel-after-buying-bandwidth/',
        title: 'Why Can’t I Create Another Channel After Purchasing Bandwidth?',
      },
      {
        uri: '/support/knowledgebase/how-much-does-it-cost-to-use-dacast/',
        title: 'How Much Does it Cost to Use Dacast?',
      },
      {
        uri: '/ufaqs/what-is-bit-rate/',
        title: 'What is Bitrate and How Does it Relate to Dacast?',
      },
      {
        uri: '/ufaqs/storage-charging-faq/',
        title: 'Dacast Video Storage Overview and Pricing FAQ',
      },
      {
        uri: '/blog/video-transcoding-service-mobile-bitrate/',
        title:
          'Video Transcoding Services: Which Video Bitrate Should I Use for Mobile Devices?',
      },
    ],
  },
  {
    title: 'Which Dacast Embed Code Should I Use?',
    content: (
      <Paragraph02 small fontWeight="regular">
        Dacast provides two embed code options for live streaming: JS and
        iframe. Both of these work for playback on your website or app via
        desktop and mobile devices as long as the content itself supports it.
        The JS embed code is the default embed code for Dacast, and it supports
        the most features. This includes being responsive if the elements are
        designed inside is a different size and working with the referrer
        restriction feature. To embed it in a WordPress website for instance,
        just copy and paste it in your article. This must be done in the text
        mode. The iframe embed code is the most universally compatible embed
        code. The caveat is that some features do not work with it.
      </Paragraph02>
    ),
    additional_resources: [
      {
        uri: '/blog/how-to-embed-streaming-video-to-your-website/',
        title: 'How to Embed Live Streaming Video on Your Website',
      },
      {
        uri:
          '/support/knowledgebase/using-embedded-players-with-dacast-walkthrough/',
        title: 'Using Embedded Players with Dacast: A Walkthrough',
      },
      {
        uri: '/support/knowledgebase/how-do-i-embed-on-a-wordpress-site/',
        title: 'How do I embed on a WordPress site?',
      },
      {
        uri:
          '/support/knowledgebase/full-screen-option-not-working-with-embed-code/',
        title:
          'Troubleshooting: The Full-Screen Option Not Working with the Embed Code',
      },
    ],
  },
  {
    title: 'In What Currency Will Dacast Issue My Paywall Revenue?',
    content: (
      <Paragraph02 small fontWeight="regular">
        For all your video monetization efforts, Dacast will give you back your
        video paywall revenue in USD.
      </Paragraph02>
    ),
    additional_resources: [
      {
        uri:
          '/ufaqs/in-what-currencies-will-dacast-give-me-back-the-revenue-from-paywall/',
        title: 'What Currencies Does Dacast Support for Payout?',
      },
      {
        uri:
          '/support/knowledgebase/how-to-set-up-a-referrer-or-a-geo-restriction-on-my-video-content/',
        title: 'How to Set Up Referrer Restrictions on Dacast',
      },
      {
        uri: '/support/knowledgebase/how-does-dacast-do-country-restrictions/',
        title: 'How Does Dacast Handle IP Video Country Restrictions?',
      },
      {
        uri:
          '/support/knowledgebase/how-to-set-up-my-security-preferences-with-the-new-back-office/',
        title: 'How to Set up Video Security Preferences',
      },
    ],
  },
  {
    title: 'Dacast Video Storage Overview and Pricing FAQ',
    content: (
      <Paragraph02 small fontWeight="regular">
        This depends on your plan level. Please log in to your Dacast account
        and click DASHBOARD and the top center box shows how much included
        storage you have.
      </Paragraph02>
    ),
    additional_resources: [
      {
        uri:
          '/support/knowledgebase/does-streaming-on-facebook-consume-bandwidth/',
        title: 'Does Streaming on Facebook Consume Bandwidth?',
      },
      {
        uri:
          '/support/knowledgebase/how-come-i-cant-create-another-channel-after-buying-bandwidth/',
        title: 'Why Can’t I Create Another Channel After Purchasing Bandwidth?',
      },
      {
        uri: '/support/knowledgebase/how-much-does-it-cost-to-use-dacast/',
        title: 'How Much Does it Cost to Use Dacast?',
      },
      {
        uri: '/ufaqs/what-is-bit-rate/',
        title: 'What is Bitrate and How Does it Relate to Dacast?',
      },
      {
        uri: '/blog/video-transcoding-service-mobile-bitrate/',
        title:
          'Video Transcoding Services: Which Video Bitrate Should I Use for Mobile Devices?',
      },
    ],
  },
];
